import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAnalytics, LazyAnimationsBubbles, LazyBlogContent, LazyBtnCallToAction, LazyBtnCallToActionAlt, LazyBtnFrame, LazyCardFrame, LazyClickCard, LazyDisplayBlokWrapper, LazyDisplayContainer, LazyDisplayError, LazyNavBar, LazyNavDesktopMenu, LazyNavFooter, LazyNavMenuMobile, LazyNavPadding, LazyNewsLetterSignup, LazyRenderRichText, LazyTopBlog, LazyTopHero, LazyBlogList, LazyButtonCallToAction, LazyButtonLink, LazyCardAccordion, LazyCardBasic, LazyLayoutCol1, LazyLayoutCol2, LazyLayoutGridW2, LazyMediaIframe, LazyMediaImage, LazyMediaRichText, LazyMediaSimpleText, LazyMediaTextWithIcon, LazyMediaTitle, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["Analytics", LazyAnalytics],
["AnimationsBubbles", LazyAnimationsBubbles],
["BlogContent", LazyBlogContent],
["BtnCallToAction", LazyBtnCallToAction],
["BtnCallToActionAlt", LazyBtnCallToActionAlt],
["BtnFrame", LazyBtnFrame],
["CardFrame", LazyCardFrame],
["ClickCard", LazyClickCard],
["DisplayBlokWrapper", LazyDisplayBlokWrapper],
["DisplayContainer", LazyDisplayContainer],
["DisplayError", LazyDisplayError],
["NavBar", LazyNavBar],
["NavDesktopMenu", LazyNavDesktopMenu],
["NavFooter", LazyNavFooter],
["NavMenuMobile", LazyNavMenuMobile],
["NavPadding", LazyNavPadding],
["NewsLetterSignup", LazyNewsLetterSignup],
["RenderRichText", LazyRenderRichText],
["TopBlog", LazyTopBlog],
["TopHero", LazyTopHero],
["BlogList", LazyBlogList],
["ButtonCallToAction", LazyButtonCallToAction],
["ButtonLink", LazyButtonLink],
["CardAccordion", LazyCardAccordion],
["CardBasic", LazyCardBasic],
["LayoutCol1", LazyLayoutCol1],
["LayoutCol2", LazyLayoutCol2],
["LayoutGridW2", LazyLayoutGridW2],
["MediaIframe", LazyMediaIframe],
["MediaImage", LazyMediaImage],
["MediaRichText", LazyMediaRichText],
["MediaSimpleText", LazyMediaSimpleText],
["MediaTextWithIcon", LazyMediaTextWithIcon],
["MediaTitle", LazyMediaTitle],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

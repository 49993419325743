import { default as _91_46_46_46slug_931YqKNGGx46Meta } from "/opt/buildhome/repo/src/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_931YqKNGGx46Meta?.name ?? "slug",
    path: _91_46_46_46slug_931YqKNGGx46Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_931YqKNGGx46Meta || {},
    alias: _91_46_46_46slug_931YqKNGGx46Meta?.alias || [],
    redirect: _91_46_46_46slug_931YqKNGGx46Meta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/[...slug].vue").then(m => m.default || m)
  }
]